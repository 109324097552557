// extracted by mini-css-extract-plugin
export var marketingStyles = "marketing-module--marketingStyles--y0+rP";
export var dlMarketingStyles = "marketing-module--dlMarketingStyles--WmfJV";
export var firstTermStyles = "marketing-module--firstTermStyles--RNcNz";
export var secondTermStyles = "marketing-module--secondTermStyles--Vd59I";
export var thirdTermStyles = "marketing-module--thirdTermStyles--bzGs4";
export var fourthTermStyles = "marketing-module--fourthTermStyles--rW26F";
export var definitionStyles = "marketing-module--definitionStyles--yi4WP";
export var hoverBg = "marketing-module--hoverBg--dnAt8";
export var definition1Styles = "marketing-module--definition1Styles--kb9+1";
export var definition2Styles = "marketing-module--definition2Styles--PUq5T";
export var definition3Styles = "marketing-module--definition3Styles--MLWSO";
export var definition4Styles = "marketing-module--definition4Styles--CzJA0";