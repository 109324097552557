// extracted by mini-css-extract-plugin
export var scaleProofStyles = "scale-proof-module--scaleProofStyles--SFzpi";
export var scaleProofArcStyles = "scale-proof-module--scaleProofArcStyles--gMVvt";
export var scaleProofArcStylesMobile = "scale-proof-module--scaleProofArcStylesMobile--OZ0d8";
export var h2ScaleProofStyles = "scale-proof-module--h2ScaleProofStyles--0RGuf";
export var ddScaleProofStyles = "scale-proof-module--ddScaleProofStyles--17ddD";
export var dlScaleProofStyles = "scale-proof-module--dlScaleProofStyles--UWxo9";
export var firstTermStyles = "scale-proof-module--firstTermStyles--ipPs1";
export var secondTermStyles = "scale-proof-module--secondTermStyles--VZ2vu";
export var thirdTermStyles = "scale-proof-module--thirdTermStyles--OXQG+";
export var markerStyles = "scale-proof-module--markerStyles--anclM";