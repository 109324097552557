import React, { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import {
  technoOrange,
  h2InnerStyles,
  centerAlignStyles,
  pStyles,
} from "../styles/global.module.css";

const environmentStyles = {
  gridArea: "4/2/5/4",
  height: "auto",
};

function Environment({ getStandAloneTimelines }) {
  const tlEnvironment = useRef();
  tlEnvironment.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("environment");
    let children = parentElement.childNodes;
    tlEnvironment.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlEnvironment.current, "tl-environment");

    return function cleanupListener() {
      try {
        tlEnvironment.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <section id="environment" className={`section`} style={environmentStyles}>
      <h2 className={`${h2InnerStyles} ${centerAlignStyles}`}>
        Environment <span className={`${technoOrange}`}>Setup</span>
      </h2>
      <p className={pStyles}>
        The selection of the best suited environment(s) and technologies for any
        software project is integral to its success. We identify must-haves
        early on and begin building out our technology stack from there. We
        never try to make our favorite tools fit your projects. This ensures
        that your projects remain lean and highly scalable. Once the environment
        has been determined we containerize the build so no matter where the
        software runs it performs exactly the same. The container environment is
        the source of truth whether the software is in the development, staging
        or production environment. One of the many advantages of this setup that
        we've grown to love is that software can be automatically rebuilt on a
        new server instances in the event of a server failure, without human
        intervention. This is excellent for high-availability projects that have
        limited human resources available for operations.
      </p>
    </section>
  );
}

export default Environment;
