// React
import * as React from "react";
import { useRef, useLayoutEffect } from "react";

// CSS
import "normalize.css";
import styled from "styled-components";

// GSAP
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Meta Information
import SEO from "../components/reusable/Seo";
import { Helmet } from "react-helmet";

// Components: HTML Structure
import VisionMatrix from "../components/structure/VisionMatrix";
import Layout from "../components/structure/Layout";
import MainContent from "../components/structure/MainContent";
import Sidebar from "../components/structure/Sidebar";
import Branding from "../components/structure/Branding";
import Footer from "../components/structure/Footer";
import ScrollCue from "../components/reusable/ScrollCue";
import LetsBuild from "../components/reusable/LetsBuild";

// Components: Sections
import Header from "../components/structure/Header";
import ScaleProof from "../components/development/ScaleProof";
import Environment from "../components/development/Environment";
import FullStack from "../components/development/FullStack";
import BrandLoyalty from "../components/development/BrandLoyalty";
import Marketing from "../components/development/Marketing";
import FinalSteps from "../components/development/FinalSteps";

const Container = styled.div`
  display: grid;
  grid-template-areas: "vision";
`;

gsap.registerPlugin(ScrollTrigger);

const DevelopmentPage = () => {
  const tlLetsBuild = useRef();
  const tlMatrix = useRef();
  const tlBranding = useRef();
  const tlSidebar = useRef();
  const tlScrollDown = useRef();
  const tlScaleProof = useRef();
  const tlEnvironment = useRef();
  const tlFullStack = useRef();
  const tlBrandLoyalty = useRef();
  const tlMarketing = useRef();
  const tlFinalSteps = useRef();

  tlLetsBuild.current = gsap.timeline();
  tlMatrix.current = gsap.timeline();
  tlBranding.current = gsap.timeline();
  tlSidebar.current = gsap.timeline();
  tlScrollDown.current = gsap.timeline();
  tlScaleProof.current = gsap.timeline();
  tlEnvironment.current = gsap.timeline();
  tlFullStack.current = gsap.timeline();
  tlBrandLoyalty.current = gsap.timeline();
  tlMarketing.current = gsap.timeline();
  tlFinalSteps.current = gsap.timeline();

  function getStandAloneTimelines(tl, name) {
    switch (name) {
      case "tl-matrix":
        tlMatrix.current.add(tl, name);
        break;
      case "tl-branding":
        tlBranding.current.add(tl, name + `+=1`);
        break;
      case "tl-sidebar":
        tlSidebar.current.add(tl, name);
        break;
      case "tl-scroll":
        tlScrollDown.current.add(tl, name);
        break;
      case "tl-scale-proof":
        tlScaleProof.current.add(tl, name);
        break;
      case "tl-environment":
        tlEnvironment.current.add(tl, name);
        break;
      case "tl-full-stack":
        tlFullStack.current.add(tl, name);
        break;
      case "tl-brand-loyalty":
        tlBrandLoyalty.current.add(tl, name);
        break;
      case "tl-marketing":
        tlMarketing.current.add(tl, name);
        break;
      case "tl-final-steps":
        tlFinalSteps.current.add(tl, name);
        break;
      case "tl-lets-build":
        tlLetsBuild.current.add(tl, name);
        break;
    }
  }

  useLayoutEffect(() => {
    const marker = document.getElementById("marker-path");

    if (marker) {
      ScrollTrigger.create({
        trigger: "#marker-path",
        start: "top 25%",
        // snap: 1 / 4,
        end: "=+200",
        toggleActions: "restart none none none",
        scrub: 1,
        // markers: true,
        animation: tlScaleProof.current,
      });
    }
    gsap.utils.toArray(".section").forEach((section, i) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top 30%",
        toggleActions: "restart none none none",
        // markers: true,
        animation: tlMatrix.current,
      });
    });
    ScrollTrigger.create({
      trigger: "header",
      start: "top 25%",
      toggleActions: "play pause resume none",
      // markers: true,
      animation: tlScrollDown.current,
      end: "bottom 10%",
    });
    ScrollTrigger.create({
      trigger: "#environment",
      start: "top 25%",
      // markers: true,
      animation: tlEnvironment.current,
    });
    ScrollTrigger.create({
      trigger: "#full-stack",
      start: "top 25%",
      // markers: true,
      animation: tlFullStack.current,
    });
    ScrollTrigger.create({
      trigger: "#brand-loyalty",
      start: "top 25%",
      // markers: true,
      animation: tlBrandLoyalty.current,
    });
    ScrollTrigger.create({
      trigger: "#marketing",
      start: "top 25%",
      // markers: true,
      animation: tlMarketing.current,
    });
    ScrollTrigger.create({
      trigger: "#final-steps",
      start: "top 25%",
      // markers: true,
      animation: tlFinalSteps.current,
    });
    ScrollTrigger.create({
      trigger: "#lets-build",
      start: "top 25%",
      // markers: true,
      animation: tlLetsBuild.current,
    });

    return function cleanupListener() {
      try {
        tlLetsBuild.current.kill();
        tlMatrix.current.kill();
        tlBranding.current.kill();
        tlSidebar.current.kill();
        tlScrollDown.current.kill();
        tlScaleProof.current.kill();
        tlEnvironment.current.kill();
        tlFullStack.current.kill();
        tlBrandLoyalty.current.kill();
        tlMarketing.current.kill();
        tlFinalSteps.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <React.Fragment>
      <SEO title="Our Development Process" article={true} />
      <Helmet>
        <title>Our Development Process</title>
      </Helmet>
      <Container>
        <Layout>
          <MainContent>
            <Branding getStandAloneTimelines={getStandAloneTimelines} />
            <Header title="Our Development" highlighted="Process">
              <ScrollCue
                getStandAloneTimelines={getStandAloneTimelines}
                scrollTo="scale-proof"
              />
            </Header>
            <ScaleProof getStandAloneTimelines={getStandAloneTimelines} />
            <Environment getStandAloneTimelines={getStandAloneTimelines} />
            <FullStack getStandAloneTimelines={getStandAloneTimelines} />
            <BrandLoyalty getStandAloneTimelines={getStandAloneTimelines} />
            <Marketing getStandAloneTimelines={getStandAloneTimelines} />
            <FinalSteps getStandAloneTimelines={getStandAloneTimelines} />
            <LetsBuild
              getStandAloneTimelines={getStandAloneTimelines}
              gridArea="9/2/10/4"
            />
            <Footer gridArea="10/2/11/4" />
          </MainContent>
          <Sidebar
            getStandAloneTimelines={getStandAloneTimelines}
            userInterface={"development"}
          />
        </Layout>
        <VisionMatrix
          matrixTimeline={getStandAloneTimelines}
          backgroundTimeline={getStandAloneTimelines}
        />
      </Container>
    </React.Fragment>
  );
};

export default DevelopmentPage;
