import React, { useLayoutEffect, useRef } from "react";
import VisionLineMesh from "../reusable/VisionLineMesh";
import { Canvas } from "@react-three/fiber";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  h3InnerStyles,
  technoOrange,
  offsetLeftStyles,
  pStyles,
} from "../styles/global.module.css";
import {
  brandLoyaltyStyles,
  synapseStyles,
} from "../styles/brand-loyalty.module.css";

import gsap from "gsap";

function BrandLoyalty({ getStandAloneTimelines }) {
  const tlBrandLoyalty = useRef();
  const breakpoints = useBreakpoint();
  tlBrandLoyalty.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("brand-loyalty");
    let children = parentElement.childNodes;
    tlBrandLoyalty.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlBrandLoyalty.current, "tl-brand-loyalty");

    return function cleanupListener() {
      try {
        tlBrandLoyalty.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  return (
    <section id="brand-loyalty" className={`section ${brandLoyaltyStyles}`}>
      <h3 className={`${h3InnerStyles}`}>
        Ideation of{" "}
        <span className={`${technoOrange} ${offsetLeftStyles}`}>
          Brand Loyalty{" "}
        </span>
        Strategy
      </h3>
      <p className={`${pStyles}`}>
        How is your business perceived? More often than not this can be very
        different from how you might think your business is perceived. Every
        decision we make, including brand perception, can be traced back to our
        Digital Roadmap Artifact (see Our Design Process). Your current brand
        perception as well as your desired brand perception informs all
        marketing initiatives associated with your project. If the project is an
        internal one we work with management to ensure we minimise hurdles to
        user adoption.
      </p>
      <div id="synapses" className={synapseStyles}>
        {breakpoints.sm ? (
          <figure>
            <img src="/synapses.svg" alt="Synapses" />
          </figure>
        ) : (
          <Canvas
            camera={{ position: [0, 0, 1150], fov: 45, near: 1, far: 4000 }}
          >
            <ambientLight intensity={0.1} />
            <directionalLight position={[0, 0, 5]} />
            <VisionLineMesh />
          </Canvas>
        )}
      </div>
    </section>
  );
}

export default BrandLoyalty;
