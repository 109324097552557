import React, { useRef, useLayoutEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

function VisionLineMesh() {
  let particleCount = 250;
  const maxParticleCount = 1000;
  const particlesData = [];
  const r = 800;
  const rHalf = r / 2;
  let segments = maxParticleCount * maxParticleCount;
  let positions = new Float32Array(segments * 3);
  let colors = new Float32Array(segments * 3);
  let particlePositions = new Float32Array(maxParticleCount * 3);

  const effectController = {
    showDots: true,
    showLines: true,
    minDistance: 150,
    limitConnections: false,
    maxConnections: 20,
    particleCount: 250,
  };

  const lineMesh = useRef();
  const linesMesh = useRef();
  const particles = useRef();
  const geometry = useRef();

  useLayoutEffect(() => {
    for (let i = 0; i < maxParticleCount; i++) {
      const x = Math.random() * r - r / 2;
      const y = Math.random() * r - r / 2;
      const z = Math.random() * r - r / 2;

      particlePositions[i * 3] = x;
      particlePositions[i * 3 + 1] = y;
      particlePositions[i * 3 + 2] = z;

      // add it to the geometry
      particlesData.push({
        velocity: new THREE.Vector3(
          -1 + Math.random() * 2,
          -1 + Math.random() * 2,
          -1 + Math.random() * 2
        ),
        numConnections: 0,
      });
    }

    particles.current.setDrawRange(0, particleCount);

    geometry.current.computeBoundingSphere();
    geometry.current.setDrawRange(0, 0);
  }, [particlesData]);

  function animate() {
    let vertexpos = 0;
    let colorpos = 0;
    let numConnected = 0;

    for (let i = 0; i < particleCount; i++) particlesData[i].numConnections = 0;

    for (let i = 0; i < particleCount; i++) {
      // get the particle
      const particleData = particlesData[i];

      particlePositions[i * 3] += particleData.velocity.x;
      particlePositions[i * 3 + 1] += particleData.velocity.y;
      particlePositions[i * 3 + 2] += particleData.velocity.z;

      if (
        particlePositions[i * 3 + 1] < -rHalf ||
        particlePositions[i * 3 + 1] > rHalf
      )
        particleData.velocity.y = -particleData.velocity.y;

      if (particlePositions[i * 3] < -rHalf || particlePositions[i * 3] > rHalf)
        particleData.velocity.x = -particleData.velocity.x;

      if (
        particlePositions[i * 3 + 2] < -rHalf ||
        particlePositions[i * 3 + 2] > rHalf
      )
        particleData.velocity.z = -particleData.velocity.z;

      if (
        effectController.limitConnections &&
        particleData.numConnections >= effectController.maxConnections
      )
        continue;

      // Check collision
      for (let j = i + 1; j < particleCount; j++) {
        const particleDataB = particlesData[j];
        if (
          effectController.limitConnections &&
          particleDataB.numConnections >= effectController.maxConnections
        )
          continue;

        const dx = particlePositions[i * 3] - particlePositions[j * 3];
        const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1];
        const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2];
        const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

        if (dist < effectController.minDistance) {
          particleData.numConnections++;
          particleDataB.numConnections++;

          const alpha = 1.0 - dist / effectController.minDistance;

          positions[vertexpos++] = particlePositions[i * 3];
          positions[vertexpos++] = particlePositions[i * 3 + 1];
          positions[vertexpos++] = particlePositions[i * 3 + 2];

          positions[vertexpos++] = particlePositions[j * 3];
          positions[vertexpos++] = particlePositions[j * 3 + 1];
          positions[vertexpos++] = particlePositions[j * 3 + 2];

          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;

          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;
          colors[colorpos++] = alpha;

          numConnected++;
        }
      }
    }

    geometry.current.setDrawRange(0, numConnected * 2);
    geometry.current.attributes.color.needsUpdate = true;
    geometry.current.attributes.position.needsUpdate = true;
    particles.current.attributes.position.needsUpdate = true;
  }

  useFrame(({ state, delta }) => {
    animate();
  });

  return (
    <mesh ref={lineMesh}>
      <group>
        <boxHelper>
          <points>
            <bufferGeometry ref={particles} attach="geometry">
              <bufferAttribute
                attachObject={["attributes", "position"]}
                array={particlePositions}
                itemSize={3}
                count={particlePositions.length / 3}
                setUsage={THREE.DynamicDrawUsage}
              />
            </bufferGeometry>
            <pointsMaterial
              attach="material"
              color="#fc4d0d"
              size="10"
              blending={THREE.AdditiveBlending}
              transparent="true"
              sizeAttenuation="false"
            ></pointsMaterial>
          </points>
          <lineSegments ref={linesMesh}>
            <bufferGeometry ref={geometry} attach="geometry">
              <bufferAttribute
                attachObject={["attributes", "position"]}
                array={positions}
                itemSize={3}
                count={positions.length / 3}
                setUsage={THREE.DynamicDrawUsage}
              />
              <bufferAttribute
                attachObject={["attributes", "color"]}
                array={colors}
                itemSize={3}
                count={colors.length / 3}
                setUsage={THREE.DynamicDrawUsage}
              />
            </bufferGeometry>
            <lineBasicMaterial
              attach="material"
              color="#fc4d0d"
              vertexColors="true"
              transparent="true"
            ></lineBasicMaterial>
          </lineSegments>
        </boxHelper>
      </group>
    </mesh>
  );
}

export default VisionLineMesh;
