import React, { useLayoutEffect, useRef, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import gsap from "gsap";

import {
  h2InnerStyles,
  centerAlignStyles,
  technoOrange,
  pStyles,
  h3InnerStyles,
} from "../styles/global.module.css";

import { finalStyles, clickStyles } from "../styles/final-steps.module.css";

function FinalSteps({ getStandAloneTimelines }) {
  const tlFinalSteps = useRef();
  const tlRotateTriangles = useRef();
  const breakpoints = useBreakpoint();
  const [activeFinalStep, setActiveFinalStep] = useState("deployment");
  tlRotateTriangles.current = gsap.timeline({
    defaults: { svgOrigin: "362 334", duration: 0.5 },
  });
  tlFinalSteps.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("final-steps");
    let children = parentElement.childNodes;
    tlFinalSteps.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlFinalSteps.current, "tl-final-steps");

    return function cleanupListener() {
      try {
        tlFinalSteps.current.kill();
        tlRotateTriangles.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    switch (activeFinalStep) {
      case "deployment":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button1 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button1 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "0_cw",
        });
        break;
      case "documentation":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button2 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button2 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "108.82_cw",
        });
        break;
      case "training":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button3 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button3 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "-131.85_cw",
        });
        break;
      case "deployment-sm":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button1 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button1 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "105_cw",
        });
        break;
      case "documentation-sm":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button2 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button2 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "225_cw",
        });
        break;
      case "training-sm":
        gsap.set("#triangles circle", { stroke: "#ffffff" });
        gsap.set("#triangles rect", { fill: "#ffffff" });
        gsap.to("#button3 circle", { stroke: "#FC4D0D", duration: 1 });
        gsap.to("#button3 rect", { fill: "#FC4D0D", duration: 1 });
        tlRotateTriangles.current.to("#triangles", {
          rotation: "345_cw",
        });
        break;
    }

    gsap.to([".active-final-tab", ".active-final-term"], {
      duration: 0.1,
      opacity: 1,
    });
    gsap.to([".inactive-final-tab", ".inactive-final-term"], {
      duration: 0.1,
      opacity: 0,
    });
  }, [activeFinalStep]);

  return (
    <section id="final-steps" className={`section ${finalStyles}`}>
      <h2 className={`${h2InnerStyles} ${centerAlignStyles}`}>
        Final <span className={`${technoOrange}`}>Steps</span>
      </h2>
      <svg
        width="550"
        height="519"
        viewBox="0 0 750 750"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="triangles">
          <path
            id="shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M422.071 97.0839L421.254 97.9004L351.941 167.213L297.108 181.906L184.192 149.306L198.776 208.254L76.1154 241.121L75 241.42L75.8165 242.236L176.368 342.787L142.119 377.035L141.303 377.852L142.418 378.151L237.102 403.521L250.368 416.788L291.32 582.315L354.808 521.228L439.022 605.441L439.838 606.258L440.137 605.142L476.942 467.786L523.724 480.321L524.839 480.62L524.54 479.505L499.171 384.825L500.074 381.454L612.753 273.035L535.13 250.624L573.079 108.995L573.378 107.879L572.263 108.178L434.905 144.983L422.37 98.1992L422.071 97.0839ZM534.169 250.347L571.964 109.294L435.164 145.949L457.182 228.12L534.169 250.347ZM456.06 227.796L434.198 146.208L352.459 168.11L329.354 191.215L456.06 227.796ZM328.256 190.898L350.527 168.627L298.968 182.443L328.256 190.898ZM199.016 209.225L221.014 298.14L177.075 342.08L76.9319 241.937L199.016 209.225ZM221.295 299.274L247.521 405.278L237.619 402.625L177.782 342.787L221.295 299.274ZM425.696 453.02L497.815 383.628L498.136 384.825L476.234 466.561L425.696 453.02ZM247.795 406.386L239.033 404.039L249.903 414.909L247.795 406.386ZM355.529 520.534L424.855 453.829L475.976 467.527L439.321 604.326L355.529 520.534ZM498.638 382.836L498.679 382.796L498.653 382.893L498.638 382.836ZM143.235 377.334L177.075 343.494L235.687 402.107L143.235 377.334ZM523.425 479.206L477.2 466.82L498.653 386.757L523.425 479.206ZM353.873 166.696L433.94 145.242L421.553 99.0157L353.873 166.696Z"
            fill="#FC4D0D"
          />

          <g
            id="button1"
            className={clickStyles}
            onClick={
              breakpoints.fs
                ? () => setActiveFinalStep("deployment-sm")
                : () => setActiveFinalStep("deployment")
            }
          >
            <circle
              id="Ellipse 124"
              cx="664.602"
              cy="261.816"
              r="34"
              stroke="#FC4D0D"
              strokeWidth="2"
              fill="#FC4D0D"
              fillOpacity="0"
            />
            <rect
              id="Rectangle 1101"
              x="658.423"
              y="255.639"
              width="13.3824"
              height="13.3824"
              rx="6.69118"
              fill="#FC4D0D"
            />
          </g>
          <g
            id="button2"
            className={clickStyles}
            onClick={
              breakpoints.fs
                ? () => setActiveFinalStep("documentation-sm")
                : () => setActiveFinalStep("documentation")
            }
          >
            <circle
              id="Ellipse 124_2"
              cx="145.225"
              cy="115"
              r="34"
              stroke="white"
              strokeWidth="2"
              fill="#FC4D0D"
              fillOpacity="0"
            />
            <rect
              id="Rectangle 1101_2"
              x="139.046"
              y="108.824"
              width="13.3824"
              height="13.3824"
              rx="6.69118"
              fill="white"
            />
          </g>
          <g
            id="button3"
            className={clickStyles}
            onClick={
              breakpoints.fs
                ? () => setActiveFinalStep("training-sm")
                : () => setActiveFinalStep("training")
            }
          >
            <circle
              id="Ellipse 124_3"
              cx="278.672"
              cy="634.167"
              r="34"
              stroke="white"
              strokeWidth="2"
              fill="#FC4D0D"
              fillOpacity="0"
            />
            <rect
              id="Rectangle 1101_3"
              x="272.493"
              y="627.991"
              width="13.3824"
              height="13.3824"
              rx="6.69118"
              fill="white"
            />
          </g>
        </g>
      </svg>
      <dl>
        <dt
          className={`${h3InnerStyles} ${technoOrange} ${
            activeFinalStep === "deployment"
              ? "active-final-term"
              : activeFinalStep === "deployment-sm"
              ? "active-final-tab"
              : "inactive-final-term"
          }`}
        >
          Deployment
        </dt>
        <dd>
          <p
            className={`${pStyles} ${
              activeFinalStep === "deployment"
                ? "active-final-tab"
                : activeFinalStep === "deployment-sm"
                ? "active-final-tab"
                : "inactive-final-tab"
            }`}
          >
            This is the part in the story where we press the big green button
            and launch your project into its new lifecycle. WOOHOO! We love good
            stories. We use a complex array of tooling to ensure this goes as
            smoothly as possible.
          </p>
        </dd>
        <dt
          className={`${h3InnerStyles} ${technoOrange} ${
            activeFinalStep === "documentation"
              ? "active-final-term"
              : activeFinalStep === "documentation-sm"
              ? "active-final-tab"
              : "inactive-final-term"
          }`}
        >
          Documentation
        </dt>
        <dd>
          <p
            className={`${pStyles} ${
              activeFinalStep === "documentation"
                ? "active-final-tab"
                : activeFinalStep === "documentation-sm"
                ? "active-final-tab"
                : "inactive-final-tab"
            }`}
          >
            We outline every detail for easy adoption and management of the
            project and this is handed over to your team. Our documentation is
            always very thorough.
          </p>
        </dd>
        <dt
          className={`${h3InnerStyles} ${technoOrange} ${
            activeFinalStep === "training"
              ? "active-final-term"
              : activeFinalStep === "training-sm"
              ? "active-final-tab"
              : "inactive-final-term"
          }`}
        >
          Training
        </dt>
        <dd>
          <p
            className={`${pStyles} ${
              activeFinalStep === "training"
                ? "active-final-tab"
                : activeFinalStep === "training-sm"
                ? "active-final-tab"
                : "inactive-final-tab"
            }`}
          >
            In addition to documentation we hold Zoom walkthrough sessions to
            get you on your way to productive usage in the shortest timeframe.
          </p>
        </dd>
      </dl>
    </section>
  );
}

export default FinalSteps;
