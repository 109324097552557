// extracted by mini-css-extract-plugin
export var fullStackStyles = "full-stack-module--fullStackStyles--9qRKs";
export var dlFullStackStyles = "full-stack-module--dlFullStackStyles--23PTb";
export var svgNumberStyles = "full-stack-module--svgNumberStyles--0TIFs";
export var dtFullStackStyles = "full-stack-module--dtFullStackStyles--RJRP1";
export var firstTermStyles = "full-stack-module--firstTermStyles--6JiQk";
export var secondTermStyles = "full-stack-module--secondTermStyles--Vb2i-";
export var thirdTermStyles = "full-stack-module--thirdTermStyles--Wx9Ea";
export var fourthTermStyles = "full-stack-module--fourthTermStyles--ZjIsE";
export var ddFullStackStyles = "full-stack-module--ddFullStackStyles--JJ2oz";
export var pFullStackStyles = "full-stack-module--pFullStackStyles--rkfRq";
export var leftArrowStyles = "full-stack-module--leftArrowStyles--K5HmV";
export var rightArrowStyles = "full-stack-module--rightArrowStyles--6aSC7";