import React, { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  h2InnerStyles,
  h3InnerStyles,
  technoOrange,
  offsetLeftStyles,
  termStyles,
  pStyles,
} from "../styles/global.module.css";
import {
  scaleProofStyles,
  h2ScaleProofStyles,
  ddScaleProofStyles,
  scaleProofArcStyles,
  scaleProofArcStylesMobile,
  firstTermStyles,
  secondTermStyles,
  thirdTermStyles,
  dlScaleProofStyles,
  markerStyles,
} from "../styles/scale-proof.module.css";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

function ScaleProof({ getStandAloneTimelines }) {
  const tlScaleProof = useRef();
  const tlScaleProofMobile = useRef();
  const breakpoints = useBreakpoint();
  const [activeMarker, setActiveMarker] = useState("marker-1-mb");
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  useLayoutEffect(() => {
    return function cleanupListener() {
      try {
        tlScaleProofMobile.current.kill();
        tlScaleProof.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    const marker1mb = document.getElementById("marker-1-mb");
    const marker2mb = document.getElementById("marker-2-mb");
    const marker3mb = document.getElementById("marker-3-mb");

    (function mediaQueryWatcher() {
      const mediaQueries = ["(min-width: 1001px)", "(max-width: 1000px)"];
      let mql;

      mediaQueries.forEach((query) => {
        if (isBrowser) {
          mql = window.matchMedia(query);
          if (mql.matches) {
            switch (query) {
              case "(min-width: 1001px)":
                scaleProofScroll();
                break;
              case "(max-width: 1000px)":
                scaleProofMobile();
                break;
            }
          }
        }
      });
    })();

    function scaleProofMobile() {
      marker1mb.addEventListener("click", moveToMarker1);
      marker2mb.addEventListener("click", moveToMarker2);
      marker3mb.addEventListener("click", moveToMarker3);

      function moveToMarker1() {
        if (activeMarker === "marker-2-mb") {
          gsap.to(["#second-dd", "#second-dt", "#third-dd", "#third-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#first-dd", "#first-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.reverse(0.5);
        } else {
          gsap.to(["#second-dd", "#second-dt", "#third-dd", "#third-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#first-dd", "#first-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.reverse(1);
          tlScaleProofMobile.current.removePause(0.5);
        }
      }

      function moveToMarker2() {
        if (activeMarker === "marker-1-mb") {
          gsap.to(["#first-dd", "#first-dt", "#third-dd", "#third-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#second-dd", "#second-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.play(0);
        } else {
          gsap.to(["#first-dd", "#first-dt", "#third-dd", "#third-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#second-dd", "#second-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.reverse(1);
        }
      }

      function moveToMarker3() {
        if (activeMarker === "marker-2-mb") {
          gsap.to(["#second-dd", "#second-dt", "#first-dd", "#first-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#third-dd", "#third-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.play(0.5);
        } else {
          gsap.to(["#second-dd", "#second-dt", "#first-dd", "#first-dt"], {
            duration: 0.5,
            opacity: 0,
          });
          gsap.to(["#third-dd", "#third-dt"], { duration: 0.5, opacity: 1 });
          tlScaleProofMobile.current.play(0);
          tlScaleProofMobile.current.removePause(0.5);
        }
      }

      let move2mb = gsap.to("#active-marker-mb", {
        motionPath:
          "M-2.869,-6.072C33.018,58.589,147.677,83.548,180.558,83.441",
        duration: 0.5,
      });
      let move3mb = gsap.to("#active-marker-mb", {
        motionPath:
          "M179.786,83.432C282.548,82.435,341.757,31.15,368.19,-0.422",
        duration: 0.5,
      });

      tlScaleProofMobile.current = gsap
        .timeline({ paused: true })
        .addLabel("move2mb")
        .add(move2mb, "0")
        .addPause()
        .addLabel("move3mb")
        .add(move3mb, "0.5")
        .addPause();

      return function cleanupListener() {
        marker1mb.removeEventListener("click", moveToMarker1);
        marker2mb.removeEventListener("click", moveToMarker2);
        marker3mb.removeEventListener("click", moveToMarker3);
      };
    }

    function scaleProofScroll() {
      let move1 = gsap.to("#active-marker", {
        motionPath: "M15.147,7.536C92.725,30.342,169.047,84.274,221.742,162.05",
        duration: 1,
      });

      let move2 = gsap.to("#active-marker", {
        motionPath:
          "M217.362,158.299C272.314,212.093,317.109,403.346,308.102,432.329",
        duration: 1,
      });

      let move3 = gsap.to("#active-marker", {
        motionPath:
          "M310.302,434.118C310.064,537.412,270.626,634.641,212.646,711.093",
        duration: 1,
      });

      gsap.set("#path1", { drawSVG: "0" });
      gsap.set("#path2", { drawSVG: "0" });
      gsap.set("#path3", { drawSVG: "0" });
      gsap.set("#path4", { drawSVG: "0" });

      tlScaleProof.current = gsap
        .timeline()
        .to("#path1", {
          drawSVG: "50%",
          duration: 1,
        })
        .add(move1, "0")
        .to("#first-dd", { opacity: 1, duration: 0.1 }, "-=0.1")
        .to("#path2", {
          drawSVG: "50%",
          duration: 1,
        })
        .add(move2, "1")
        .to("#first-dd", { opacity: 0, duration: 0.1 }, "-=0.1")
        .to("#second-dd", { opacity: 1, duration: 0.1 }, "-=0.1")
        .to("#path3", {
          drawSVG: "50%",
          duration: 1,
        })
        .add(move3, "2")
        .to("#second-dd", { opacity: 0, duration: 0.1 }, "-=0.1")
        .to("#third-dd", { opacity: 1, duration: 0.1 }, "-=0.1")
        .to("#path4", {
          drawSVG: "50%",
          duration: 1,
        });
      getStandAloneTimelines(tlScaleProof.current, "tl-scale-proof");
    }
  }, [activeMarker]);
  return (
    <section id="scale-proof" className={`section ${scaleProofStyles}`}>
      <h2 className={`${h2InnerStyles} ${h2ScaleProofStyles}`}>
        Scale-Proof{" "}
        <span className={`${technoOrange} ${offsetLeftStyles}`}>
          Development
        </span>
      </h2>

      <svg
        className={scaleProofArcStylesMobile}
        width="443"
        height="170"
        viewBox="0 0 443 159"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="path1-mb"
          d="M40.4994 63.4022V64.5529C24.2898 45.8945 10.9019 24.719 1 1.69086C1.19782 1.51611 1.39646 1.34229 1.59592 1.1694C11.3605 23.9424 24.5435 44.9018 40.4994 63.4022Z"
          fill="#FC4D0D"
          stroke="#FC4D0D"
        />
        <path
          id="path2-mb"
          d="M227.499 147.522C225.839 147.556 224.174 147.573 222.506 147.573C149.84 147.574 84.6861 115.415 40.4994 64.5529V63.4022C84.5566 114.485 149.755 146.821 222.506 146.82C224.174 146.82 225.839 146.803 227.499 146.769V147.522Z"
          stroke="#FC4D0D"
        />
        <path
          id="path3-mb"
          d="M227.499 146.769C323.024 144.82 404.856 87.1178 441.782 4.87784C441.973 5.06631 442.163 5.25571 442.352 5.44598C405.253 87.8099 323.229 145.575 227.499 147.522V146.769Z"
          stroke="#FC4D0D"
        />
        <circle
          id="marker-1-mb"
          className={markerStyles}
          onClick={() => setActiveMarker("marker-1-mb")}
          cx="40"
          cy="62"
          r="12"
          fill="#FC4D0D"
        />
        <circle
          id="marker-2-mb"
          className={markerStyles}
          onClick={() => setActiveMarker("marker-2-mb")}
          cx="222"
          cy="147"
          r="12"
          fill="#FC4D0D"
        />
        <circle
          id="marker-3-mb"
          className={markerStyles}
          onClick={() => setActiveMarker("marker-3-mb")}
          cx="405"
          cy="62"
          r="12"
          fill="#FC4D0D"
        />
        <circle
          id="active-marker-mb"
          cx="40"
          cy="62"
          r="13.5761"
          fill="#FC4D0D"
          stroke="white"
          strokeWidth="8.84783"
        />
      </svg>
      {breakpoints.md ? null : (
        <svg
          className={scaleProofArcStyles}
          width="354"
          height="882"
          viewBox="0 0 334 882"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="marker-path"
            d="M325.5 449.5C325.5 650.186 196.142 820.659 16.2578 882.086V881.028C195.579 819.643 324.5 649.628 324.5 449.5C324.5 249.372 195.579 79.3572 16.2578 17.9718V16.9143C196.142 78.3408 325.5 248.815 325.5 449.5Z"
            stroke="#1a1a1a"
          />
          <path
            id="path1"
            d="M16.5 17.0547V16.5H17.9618C105.787 46.8805 181.466 103.288 235.776 176.5H234.53C180.138 103.424 104.379 47.1931 16.5 17.0547Z"
            stroke="#FC4D0D"
            strokeWidth="2"
          />
          <path
            id="path2"
            d="M235.981 177.103L235.177 177.698C295.559 259.272 324.685 354.349 324.692 448.603L325.692 447.863C325.533 353.644 296.339 258.644 235.981 177.103Z"
            stroke="#FC4D0D"
            strokeWidth="2"
          />
          <path
            id="path3"
            d="M325.499 449.5C325.278 552.806 290.78 648.061 232.775 724.5H231.518C289.673 648.127 324.277 552.85 324.499 449.5H325.499Z"
            stroke="#FC4D0D"
            strokeWidth="2"
          />
          <path
            id="path4"
            d="M230.755 725.5H232.014C177.639 796.748 102.794 851.536 16.2578 881.086V880.028C102.224 850.6 176.607 796.207 230.755 725.5Z"
            stroke="#FC4D0D"
            strokeWidth="2"
          />
          <circle cx="231.641" cy="725.145" r="8.57812" fill="#FC4D0D" />
          <circle cx="324.578" cy="448.578" r="8.57812" fill="#FC4D0D" />
          <circle cx="236.645" cy="176.86" r="8.57812" fill="#FC4D0D" />
          <circle
            id="active-marker"
            cx="16.4414"
            cy="16.4414"
            r="10.9414"
            fill="#FC4D0D"
            stroke="white"
            strokeWidth="11"
          />
        </svg>
      )}
      <dl className={dlScaleProofStyles}>
        <dt id="first-dt" className={`${termStyles} ${firstTermStyles}`}>
          Twelve-Factor
        </dt>
        <dd id="first-dd" className={`${ddScaleProofStyles}`}>
          <h3 className={`${h3InnerStyles}`}>
            Twelve-Factor App{" "}
            <span className={`${technoOrange}`}>Development</span>
          </h3>
          <p className={`${pStyles}`}>
            Maintainable, globally accessible &amp; lean, this has become the
            de-facto standard for developing cloud-native builds. We
            progressively integrate these factors.
          </p>
        </dd>
        <dt id="second-dt" className={`${termStyles} ${secondTermStyles}`}>
          Test Driven
        </dt>
        <dd id="second-dd" className={`${ddScaleProofStyles}`}>
          <h3 className={`${h3InnerStyles}`}>
            Test-Driven <span className={`${technoOrange}`}>Development</span>
          </h3>
          <p className={`${pStyles}`}>
            Any pattern that brings more clarity to thought processes during
            development is beneficial. TDD has numerous downstream benefits but
            we love it for the focus it places on quality and efficient code
            writing.
          </p>
        </dd>
        <dt id="third-dt" className={`${termStyles} ${thirdTermStyles}`}>
          Continuous Integration
        </dt>
        <dd id="third-dd" className={`${ddScaleProofStyles}`}>
          <h3 className={`${h3InnerStyles}`}>
            Continuous <span className={`${technoOrange}`}>Integration</span>
          </h3>
          <p className={`${pStyles}`}>
            Every build is configured from lift off for CI. Setting up CI for
            each project allows us to reduce deployment times and push more
            changes to your build without down-time. Every second counts.
          </p>
        </dd>
      </dl>
    </section>
  );
}

export default ScaleProof;
