import React, { useLayoutEffect, useRef, useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import gsap from "gsap";

import {
  h2InnerStyles,
  rightAlignStyles,
  technoOrange,
  rightAmpersandStyles,
  termStyles,
  pStyles,
} from "../styles/global.module.css";

import {
  marketingStyles,
  dlMarketingStyles,
  firstTermStyles,
  secondTermStyles,
  thirdTermStyles,
  fourthTermStyles,
  definitionStyles,
  hoverBg,
  definition1Styles,
  definition2Styles,
  definition3Styles,
  definition4Styles,
} from "../styles/marketing.module.css";

function Marketing({ getStandAloneTimelines }) {
  const tlHover = useRef();
  const tlMarketing = useRef();
  const breakpoints = useBreakpoint();
  const [activeMarketingTab, setActiveMarketingTab] = useState("first-m-term");
  tlHover.current = gsap.timeline();
  tlMarketing.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("marketing");
    let children = parentElement.childNodes;
    tlMarketing.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });

    getStandAloneTimelines(tlMarketing.current, "tl-marketing");

    return function cleanupListener() {
      try {
        tlHover.current.kill();
        tlMarketing.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    switch (activeMarketingTab) {
      case "first-m-term":
        gsap.set("#hover-bg", { gridArea: "term1" });
        gsap.to(".active-m-tab", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to(".active-m-term", { duration: 0.1, fontWeight: "bold" });
        break;
      case "first-m-term-sm":
        gsap.killTweensOf(".inactive-m-tab");
        gsap.set("#hover-bg", { gridArea: "term1" });
        gsap.to(".active-m-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to("#marketing-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em min-content 4.2em 0.1em 4.2em 0.1em 4.2em 0.1em",
        });
        gsap.to(".active-m-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "second-m-term":
        gsap.set("#hover-bg", { gridArea: "term2" });
        gsap.to(".active-m-tab", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to(".active-m-term", { duration: 0.1, fontWeight: "bold" });
        break;
      case "second-m-term-sm":
        gsap.killTweensOf(".inactive-m-tab");
        gsap.set("#hover-bg", { gridArea: "term2" });
        gsap.to(".active-m-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#marketing-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em min-content 4.2em 0.1em 4.2em 0.1em",
        });
        gsap.to(".active-m-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "third-m-term":
        gsap.set("#hover-bg", { gridArea: "term3" });
        gsap.to(".active-m-tab", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to(".active-m-term", { duration: 0.1, fontWeight: "bold" });
        break;
      case "third-m-term-sm":
        gsap.killTweensOf(".inactive-m-tab");
        gsap.set("#hover-bg", { gridArea: "term3" });
        gsap.to(".active-m-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#marketing-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em 0.1em 4.2em min-content 4.2em 0.1em",
        });
        gsap.to(".active-m-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "fourth-m-term":
        gsap.set("#hover-bg", { gridArea: "term4" });
        gsap.to(".active-m-tab", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to(".active-m-term", { duration: 0.1, fontWeight: "bold" });
        break;
      case "fourth-m-term-sm":
        gsap.killTweensOf(".inactive-m-tab");
        gsap.set("#hover-bg", { gridArea: "term4" });
        gsap.to(".active-m-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#marketing-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em 0.1em 4.2em 0.1em 4.2em min-content",
        });
        gsap.to(".active-m-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
    }
    gsap.set(".inactive-m-tab", { duration: 0.1, opacity: 0 });
    gsap.set(".inactive-m-term", { duration: 0.1, fontWeight: "100" });

    tlHover.current
      .from("#rect1-bg", { duration: 0.5, translateX: -200 })
      .from("#rect2-bg", { duration: 0.5, translateX: 200 }, "-=0.5")
      .from("#rect3-bg", { duration: 0.5, translateX: 200 }, "-=0.5");
  }, [activeMarketingTab]);
  return (
    <section id="marketing" className={`section ${marketingStyles}`}>
      <h2 className={`${h2InnerStyles} ${rightAlignStyles}`}>
        Marketing{" "}
        <span className={`${technoOrange} ${rightAmpersandStyles}`}>&amp;</span>
        Sales
      </h2>
      <dl id="marketing-dl" className={dlMarketingStyles}>
        <svg
          id="hover-bg"
          className={hoverBg}
          width="750"
          height="75"
          viewBox="0 0 450 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect id="main-rect-bg" width="750" height="75" fill="white" />
          <rect id="rect1-bg" x="106" width="16" height="75" fill="#101010" />
          <rect id="rect2-bg" x="60" width="16" height="75" fill="#101010" />
          <rect id="rect3-bg" x="25" width="16" height="75" fill="#101010" />
        </svg>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveMarketingTab("first-m-term-sm")
              : () => setActiveMarketingTab("first-m-term")
          }
          className={`${firstTermStyles} ${termStyles} ${technoOrange} ${
            activeMarketingTab === "first-m-term"
              ? "active-m-term"
              : activeMarketingTab === "first-m-term-sm"
              ? "active-m-term-sm"
              : "inactive-m-term"
          }`}
        >
          SEO Strategy
        </dt>
        <dd
          className={`${definition1Styles} ${definitionStyles} ${
            activeMarketingTab === "first-m-term"
              ? "active-m-tab"
              : activeMarketingTab === "first-m-term-sm"
              ? "active-m-tab-sm"
              : "inactive-m-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            We take advantage of several factors as it relates to SEO. Unless
            the project requires us to deviate from guidelines we adhere to
            usability and accessibility guidelines which means our projects
            start on the right footing from their foundation. This paired with
            semantic coding practices means we can focus our attention on higher
            level SEO strategies after the build is complete. From Google Search
            Console to SEM Rush, we utilize several tools to monitor SEO
            performance based on the requirements and budget constraints of your
            project.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveMarketingTab("second-m-term-sm")
              : () => setActiveMarketingTab("second-m-term")
          }
          className={`${secondTermStyles} ${termStyles} ${technoOrange} ${
            activeMarketingTab === "second-m-term"
              ? "active-m-term"
              : activeMarketingTab === "second-m-term-sm"
              ? "active-m-term-sm"
              : "inactive-m-term"
          }`}
        >
          Social Strategy
        </dt>
        <dd
          className={`${definition2Styles} ${definitionStyles} ${
            activeMarketingTab === "second-m-term"
              ? "active-m-tab"
              : activeMarketingTab === "second-m-term-sm"
              ? "active-m-tab-sm"
              : "inactive-m-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            The pulse of your business should be felt through social media
            channels. We build social media strategies that are in alignment
            with the audience you are targeting and continuously tweak it as we
            acquire new knowledge. We don't ever advocate creating social
            channels because its popular as we believe all messaging should be
            intentional and channels that cannot be operated effectively and in
            alignment with your messaging, inevitably contribute to negative
            brand perception.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveMarketingTab("third-m-term-sm")
              : () => setActiveMarketingTab("third-m-term")
          }
          className={`${thirdTermStyles} ${termStyles} ${technoOrange} ${
            activeMarketingTab === "third-m-term"
              ? "active-m-term"
              : activeMarketingTab === "third-m-term-sm"
              ? "active-m-term-sm"
              : "inactive-m-term"
          }`}
        >
          Paid Media Strategy
        </dt>
        <dd
          className={`${definition3Styles} ${definitionStyles} ${
            activeMarketingTab === "third-m-term"
              ? "active-m-tab"
              : activeMarketingTab === "third-m-term-sm"
              ? "active-m-tab-sm"
              : "inactive-m-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            These days, ads are near impossible to get away from either as a
            consumer or a provider. Having a solid ad strategy can have a
            massive impact on your bottom line. We help you make sense of it
            all. Just as with everything else relating to your business, ad
            strategies must be financially feasible. We calculate your initial
            Customer Acquisition Cost and work out how much you should actually
            spend on ads using your average Customer Lifetime Value, then build
            campaigns using these and other parameters. A/B testing also helps
            us to fine tune your ads for efficiency.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveMarketingTab("fourth-m-term-sm")
              : () => setActiveMarketingTab("fourth-m-term")
          }
          className={`${fourthTermStyles} ${termStyles} ${technoOrange} ${
            activeMarketingTab === "fourth-m-term"
              ? "active-m-term"
              : activeMarketingTab === "fourth-m-term-sm"
              ? "active-m-term-sm"
              : "inactive-m-term"
          }`}
        >
          Conversion Monitoring
        </dt>
        <dd
          className={`${definition4Styles} ${definitionStyles} ${
            activeMarketingTab === "fourth-m-term"
              ? "active-m-tab"
              : activeMarketingTab === "fourth-m-term-sm"
              ? "active-m-tab-sm"
              : "inactive-m-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            Although conversions usually refer to sales, they can be any
            indicator that represents an intended objective being met because of
            your digital efforts. Conversions need to be monitored closely to
            ensure strategies are performing as planned, especially during
            campaigns. We use specialised tools to monitor all conversions so we
            always know the status of campaigns and if the course taken needs to
            be updated. For example, conversion rates are used to determine the
            performance of A/B tests as this is a direct indicator of how well
            one ad has performed in comparison to another.
          </p>
        </dd>
      </dl>
    </section>
  );
}

export default Marketing;
